import { useContext } from 'react';

import { useParams } from 'react-router-dom';

import { AppContext } from '../scripts/context'

export default function Media( {mediaVis, mediaOpa, titleVis, titleOpa} ) {

    const { page } = useParams();

    const {
		scrollToTitle
    } = useContext(AppContext);

    let media = {
        img:
        <img id="media" alt="" src="/xt_3.jpg"/>,

        title:
        <div id="title">WELCOME</div>,

        text:
        <div id="title_text">Join our efforts to design the most efficient paths to reliable and timeless solutions</div>,
    };

    function handleReadMoreClick() {

        scrollToTitle("smooth");

    };

    if (!page || page === "about") {

        media.img = <img id="media" alt="njb_design_xthrust_0.jpg" src="/media/njb_design_xthrust_0.jpg"/>;
        media.title = <div id="title">WELCOME</div>;
        media.text = <div id="title_text">Join our efforts to design the most efficient paths to reliable and timeless solutions</div>;

    } else if (page === "cgi") {

        media.img = <img id="media" alt="suss_jetx_m_1.jpg" src="/media/suss_jetx_m_1.jpg"/>;
        media.title = <div id="title">CGI</div>;
        media.text = <div id="title_text">Digital production to make sense of reality</div>;

    } else if (page === "games") {

        media.img = <img id="media" alt="njb_design_xthrust_1.jpg" src="/media/njb_design_xthrust_1.jpg"/>;
        media.title = <div id="title">GAMES</div>;
        media.text = <div id="title_text">Independent projects and unique assets that will keep you entertained</div>;

    } else if (page === "design") {

        media.img = <img id="media" alt="" src="/Curve_rgb.jpg"/>;
        media.title = <div id="title">DESIGN</div>;
        media.text = <div id="title_text">Custom design and engineering that reflects identity</div>;

    } else if (page === "store") {

        media.img = <img id="media" alt="njb_design_xthrust_1.jpg" src="/media/njb_design_xthrust_1.jpg"/>;
        media.title = <div id="title">STORE</div>;
        media.text = <div id="title_text">Back our ventures by getting selected products to represent our brand</div>;

    };

    return (
        <div id="media_container" style={{visibility: mediaVis, opacity: mediaOpa}}>
            <div id="media_container_content">
                {media.img}
            </div>
            <div id="title_container" style={{visibility: titleVis, opacity: titleOpa}}>
                {media.title}
                {media.text}
                <div id="button_read_more" onClick={() => {handleReadMoreClick()}}>Read more...</div>
            </div>
        </div>
    );

};