import { useEffect, useRef, useState, useContext } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import { AppContext } from '../scripts/context'
import { GetSectionName } from '../scripts/utilities';

export default function Main( {mainVis} ) {

    const navigate = useNavigate();
    
    const { page } = useParams();
    const { section } = useParams();
    const { item } = useParams();
    
    const itemsSectionRef = useRef();

    const thumbnailIds = useRef([]);
    const thumbnailPaths = useRef([]);
    const thumbnailAlts = useRef([]);
    const thumbnailTitles = useRef([]);

    const [itemsContainerControlsVisLeft, setItemsContainerControlsVisLeft] = useState(false);
    const [itemsContainerControlsVisRight, setItemsContainerControlsVisRight] = useState(false);

    const {
		scrollToBottom,
        pageElements
    } = useContext(AppContext);
    
    const mainContent = useRef({
        text:
        <div id="text_container">
        </div>
    });

    let pointerStartX = 0;
    let pointerCurrentX = 0;
    let scrollStartX = 0;
    let itemAction = false;
    let itemCursor = false;

    let nextItemScrollPos;
    let previousItemScrollPos;
    const itemWidth = 256;
    const itemGap = 32;
    let scrollCenter;
    let scrollDistance;
    let scrollFirstPos;
    let scrollLastPos;

    function handleThumbnailClick(itemId) {

        let sectionName = GetSectionName(page);

        if ("/" + page + "/" + sectionName + "/" + itemId === "/" + page + "/" + section + "/" + item) {

            scrollToBottom("smooth");

        } else {

            navigate("/" + page + "/" + sectionName + "/" + itemId);

        };

    };

    function updateItemsScrollPositions() {

        scrollCenter = (itemsSectionRef.current.scrollWidth - window.innerWidth) / 2;
        scrollDistance = (thumbnailIds.current.length - 1) * (itemWidth + itemGap);
        scrollFirstPos = scrollCenter - scrollDistance / 2;
        scrollLastPos = scrollCenter + scrollDistance / 2;

    };

    function goToNextItem() {

        for (let i = 0; i <= thumbnailIds.current.length - 1; i++) {

            if (scrollFirstPos + i * (itemGap + itemWidth) > itemsSectionRef.current.scrollLeft + 1) {
                nextItemScrollPos = scrollFirstPos + i * (itemGap + itemWidth);
                break;
            };

        };

        scrollToItem(nextItemScrollPos);

    };

    function goToPreviousItem() {

        for (let i = 0; i <= thumbnailIds.current.length - 1; i++) {

            if (scrollLastPos - i * (itemGap + itemWidth) < itemsSectionRef.current.scrollLeft - 1) {
                previousItemScrollPos = scrollLastPos - i * (itemGap + itemWidth);
                break;
            };

        };

        scrollToItem(previousItemScrollPos);

    };

    function scrollToItem(position) {

        itemsSectionRef.current.scrollTo({
            top: 0,
            left: position,
            behavior: "smooth",
        });

    };

    function calcItemsContainerControlsVis() {
    
        if (thumbnailIds.current.length > 0 && thumbnailIds.current.length * itemWidth + (thumbnailIds.current.length - 1) * itemGap > window.innerWidth) {
            if (itemsSectionRef.current.scrollLeft < scrollFirstPos + 64) {
                setItemsContainerControlsVisRight(true);
                setItemsContainerControlsVisLeft(false);
            } else if (itemsSectionRef.current.scrollLeft > scrollLastPos - 64) {
                setItemsContainerControlsVisLeft(true);
                setItemsContainerControlsVisRight(false);
            } else {
                setItemsContainerControlsVisLeft(true);
                setItemsContainerControlsVisRight(true);
            };
        } else {
            setItemsContainerControlsVisLeft(false);
            setItemsContainerControlsVisRight(false);
        };

    };

    function centerItemsSection() {

        itemsSectionRef.current.scrollLeft = scrollCenter;

    };

    function handleResize() {

        calcItemsContainerControlsVis();
        centerItemsSection();
        updateItemsScrollPositions();

    };

    function handleItemSectionScrollEnd() {

        calcItemsContainerControlsVis();

    };

    function handleItemSectionScroll() {

        calcItemsContainerControlsVis();

    };

    if (!page || page === "about") {

        mainContent.current.text =
            <div id="text_container">
                <h1>WELCOME</h1>
                <h2>Join our efforts to design the most efficient paths to reliable and timeless solutions</h2>
                <p>Over 16 years in different fields of technology we obtained an agile set of interoperable skills and procedures. Connecting digital technologies with a holistic approach enables us to find reliable and timeless solutions for various industries. Every project is a piece of our evolution and becomes part of our identity.</p>
            </div>;

        thumbnailIds.current = [];
        thumbnailPaths.current = [];
        thumbnailAlts.current = [];
        thumbnailTitles.current = [];

    } else if (page === "cgi") {

        mainContent.current.text =
            <div id="text_container">
                <h1>CGI</h1>
                <h2>Digital production to make sense of reality</h2>
                <p>Experience from over 500 B2B projects in CGI gives us the intel to provide tailored support to realize your visions in the digital universe. We analyse your digital environments to define measures for the implementation of new technologies and workflows in nondisruptive ways.</p>
                <br/>
                <h2>Selected Projects</h2>
            </div>;

        thumbnailIds.current = ["opp2000", "fabia", "jetx", "dw291"];
        thumbnailPaths.current = ["/media/osterwalder_opp2000_t.jpg", "/media/meyerburger_fabia_t.jpg", "/media/suss_jetx_t.jpg", "/media/peterwolters_dw291_t.jpg"];
        thumbnailAlts.current = ["osterwalder_opp2000_t.jpg", "meyerburger_fabia_t.jpg", "suss_jetx_t.jpg", "peterwolters_dw291_t.jpg"];
        thumbnailTitles.current = ["opp 2000", "fabia", "jetx", "dw 291"];

    } else if (page === "games") {

        mainContent.current.text =
            <div id="text_container">
                <h1>GAMES</h1>
                <h2>Independent projects and unique assets that will keep you entertained</h2>
                <p>Developing games is our source of innovation in the digital universe. In our productions we focus on a high degree of in-house development to accurately represent our ideas and identity. We have reached over 250,000 players and continue to create projects for people that appreciate our attention to detail.</p>
                <br/>
                <h2>Selected Projects</h2>
            </div>;

        thumbnailIds.current = ["xthrust", "ammomaker"];
        thumbnailPaths.current = ["/media/njb_design_xthrust_t.jpg", "/ammo_maker_thumbnail.png"];
        thumbnailAlts.current = ["njb_design_xthrust_t.jpg", "ammo_maker_thumbnail.png"];
        thumbnailTitles.current = ["xthrust", "ammo maker"];

    } else if (page === "design") {

        mainContent.current.text =
            <div id="text_container">
                <h1>DESIGN</h1>
                <h2>Custom design and engineering for bespoke applications that reflects identity</h2>
                <p>We design based on your functionality requirements. The accurate analysis of environments and our wide knowledge about manufacturing technologies, are our guidelines for the selection of materials and use of form. We coordinate the production of prototypes and series with our partners and optimize processes to meet your quality standards.</p>
                <br/>
                <h2>Selected Projects</h2>
            </div>;

        thumbnailIds.current = ["curve", "wristband"];
        thumbnailPaths.current = ["/curve_t.jpg", "/media/njb_design_wristband_t.jpg"];
        thumbnailAlts.current = ["curve_t.jpg", "wristband_t.jpg"];
        thumbnailTitles.current = ["curve", "wristband"];

    } else if (page === "store") {

        mainContent.current.text =
            <div id="text_container">
                <h1>STORE</h1>
                <h2>Back our ventures by getting selected products and merch to represent our brand</h2>
                <p>It's our goal to offer exclusive items that will make you happy. We source all our products from reliable partners and manufacturers that meet our high expectations regarding quality and ethics. Every purchase directly supports our ability to focus on research and projects for the digital community.</p>
                <br/>
                <h2>Selected Products</h2>
            </div>;

        thumbnailIds.current = ["njb_design_sticker", "ammo_maker_sticker", "xthrust_sticker"];
        thumbnailPaths.current = ["/media/njb_design_xthrust_t.jpg", "/ammo_maker_thumbnail.png", "/media/njb_design_xthrust_t.jpg"];
        thumbnailAlts.current = ["njb_design_xthrust_t.jpg", "ammo_maker_thumbnail.png", "njb_design_xthrust_t.jpg"];
        thumbnailTitles.current = ["sticker", "sticker", "sticker"];

    };

    //pointer interaction
    function pointerDownItemSection(e) {
        if (e.pointerType === "mouse") {
            itemsSectionRef.current.onpointermove = pointerMoveItemSection;

            pointerStartX = e.screenX;
            scrollStartX = itemsSectionRef.current.scrollLeft;
        };
    };

    function pointerMoveItemSection(e) {
        if (e.pointerType === "mouse") {
            itemsSectionRef.current.setPointerCapture(e.pointerId);

            itemAction = false;

            pointerCurrentX = e.screenX;
            itemsSectionRef.current.scrollLeft = scrollStartX + (pointerStartX - pointerCurrentX);

            itemsSectionRef.current.style.cursor = 'grabbing';
        };
    };

    function pointerUpItemSection(e, itemId) {
        if (e.pointerType === "mouse") {
            itemsSectionRef.current.onpointermove = null;
            itemsSectionRef.current.releasePointerCapture(e.pointerId);

            if (itemAction === true) {
                handleThumbnailClick(itemId);
                itemAction = false;
            };

            if (itemCursor === true) {
                itemsSectionRef.current.style.cursor = 'pointer';
            } else {
                itemsSectionRef.current.style.cursor = 'grab';
            };
            itemCursor = false;

            calcItemsContainerControlsVis();

        };
    };

    function pointerDownItem(e) {
        if (e.pointerType === "mouse") {
            itemAction = true;
            itemCursor = true;
        };
    };

    function pointerLeaveItem(e) {
        if (e.pointerType === "mouse") {
            itemsSectionRef.current.style.cursor = 'grab';
        };
    };

    //touch interaction
    function touchStartItem(e) {
        itemAction = true;
    };

    function touchMoveItemSection(e) {
        itemAction = false;
    };

    function touchEndItem(e, itemId) {
        if (itemAction === true) {

            handleThumbnailClick(itemId);
            itemAction = false;

        };

        calcItemsContainerControlsVis();

    };

    //useEffect on every render
    useEffect(() => {

        updateItemsScrollPositions();
                                                                                                                                                // eslint-disable-next-line
    });

    //useEffect on initial render
    useEffect(() => {

        window.addEventListener("resize", handleResize);

        return () => {

            window.removeEventListener("resize", handleResize);

        };
                                                                                                                                                // eslint-disable-next-line
    }, []);

    //useEffect on page parameter change
    useEffect(() => {
        
        calcItemsContainerControlsVis();
        centerItemsSection();

        let itemSectionRef = itemsSectionRef.current
        itemSectionRef.addEventListener("scroll", handleItemSectionScroll);
        itemSectionRef.addEventListener("scrollend", handleItemSectionScrollEnd);

        return () => {

            itemSectionRef.removeEventListener("scroll", handleItemSectionScroll);
            itemSectionRef.removeEventListener("scrollend", handleItemSectionScrollEnd);

        };
                                                                                                                                                // eslint-disable-next-line
    }, [page]);

    return (
        <div id="main_container" style={{visibility: mainVis}}>
            <div id="main_container_content" ref={pageElements.mainContainerContent}>
                {mainContent.current.text}
                <div id="items_section" ref={itemsSectionRef} onPointerDown={pointerDownItemSection} onPointerUp={pointerUpItemSection} onTouchMove={touchMoveItemSection}>
                    <div id="items_container">
                        {
                            thumbnailPaths.current.map((value, index)=>{
                                return(
                                    <div id="thumbnail_container" key={index}>
                                            <img id="thumbnail_image" src={value} alt={thumbnailAlts.current[index]} width="100%" height="100%" />
                                            <p id="thumbnail_title">{thumbnailTitles.current[index]}</p>
                                            <svg version="1.1" id="thumbnail_link_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 256 256" preserveAspectRatio="xMinYMin meet" pointerEvents="none">
                                                <path 
                                                    id="thumbnail_link_area"
                                                    pointerEvents="auto"
                                                    onTouchStart={touchStartItem}
                                                    onTouchEnd={(e) => {touchEndItem(e, thumbnailIds.current[index])}}
                                                    onPointerDown={pointerDownItem}
                                                    onPointerUp={(e) => {pointerUpItemSection(e, thumbnailIds.current[index])}}
                                                    onPointerLeave={pointerLeaveItem}
                                                    opacity="0"
                                                    fill="#000000"
                                                    d="M85.9,0c-6.6,0-14.7,4.677-18,10.392L0,128v128h115.997c6.6,0,15.818-3.818,20.485-8.485L256,128V0H85.9z"
                                                />
                                            </svg>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {itemsContainerControlsVisLeft ? 
                        <svg version="1.1" id="items_controls_left_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" preserveAspectRatio="xMinYMid meet" pointerEvents="none">
                            <path id="detail_controls_left" fill="#FFFFFF" d="M16.825,22.003c-1.1,1.101-1.1,2.896,0,3.995l14.079,14.079c1.098,1.1,1.428,0.857,0.734-0.531l-6.514-13.02c-0.691-1.391-0.691-3.664,0-5.052l6.514-13.022c0.69-1.39,0.361-1.628-0.734-0.526L16.825,22.003z"/>
                            <rect id="detail_controls_left_button" opacity="0" x="0" y="0" width="48" height="48" pointerEvents="auto" onClick={() => {goToPreviousItem()}}/>
                        </svg>
                    : null}
                    {itemsContainerControlsVisRight ? 
                        <svg version="1.1" id="items_controls_right_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" preserveAspectRatio="xMaxYMid meet" pointerEvents="none">
                            <path id="detail_controls_right" fill="#FFFFFF" d="M31.175,25.997c1.1-1.1,1.1-2.896,0-3.995L17.096,7.923c-1.098-1.1-1.428-0.857-0.734,0.531l6.514,13.02c0.691,1.391,0.691,3.664,0,5.051l-6.514,13.022c-0.69,1.39-0.361,1.628,0.734,0.526L31.175,25.997z"/>
                            <rect id="detail_controls_right_button" opacity="0" x="0" y="0" width="48" height="48" pointerEvents="auto" onClick={() => {goToNextItem()}}/>
                        </svg>
                    : null}
                </div>
            </div>
        </div>
    );

};