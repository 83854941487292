import React, { useEffect, useRef, useContext } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { AppContext } from '../scripts/context'

const PayPalButton = ({ country, cartContent, setPaypalOrderIdRef, setError }) => {
  const countryRef = useRef(country);
  const cartContentRef = useRef(cartContent);
  const { setPaypalSuccessState } = useContext(AppContext);

  useEffect(() => {
    countryRef.current = country;
    cartContentRef.current = cartContent;
  }, [country, cartContent]);

  const createOrder = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country: countryRef.current, cartContent: cartContentRef.current }),
      });

      if (!response.ok) {
        throw new Error('Failed to create order');
      }

      const data = await response.json();
      //setOrderID(data.id);
      setPaypalOrderIdRef(data.id)
      return data.id;
    } catch (error) {
      setError(`Order creation failed: ${error.message}. Please try again.`);
    }
  };

  const onApprove = async (data) => {
    try {
       // Validate payment on the server
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/validate-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderID: data.orderID }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate payment');
      }

      const validationData = await response.json();

      if (validationData.status !== 'COMPLETED') {
        throw new Error('Payment not completed');
      }

      setPaypalSuccessState(true);
    } catch (error) {
      setError(`Payment validation failed: ${error.message}. Please contact support.`);
    }
  };

  return (
    <div>
      <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, currency: "CHF" }}>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          onError={(err) => setError(`PayPal Button error: ${err.message}. Please try again.`)}
          fundingSource="paypal"
          style={{
            layout: 'vertical',         // or 'horizontal', 'vertical'
            color: 'black',             // or 'gold', 'silver', 'white', 'black', 'blue'
            shape: 'rect',              // or 'pill'
            label: 'paypal',            // or 'checkout', 'buynow', 'pay', 'installment'
            tagline: false,             // or true
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default PayPalButton;