import { useEffect, useRef, useState, useContext } from 'react';

import { useParams } from 'react-router-dom';

import { AppContext } from '../scripts/context'

export default function Detail() {

    const { page } = useParams();
    const { item } = useParams();

    const itemRef = useRef();

    const detailContainerRef = useRef();

    const mediaTopRef = useRef();
    const mediaBottomRef = useRef();

    const mediaFadeRunningRef = useRef(false);

    const mediaCurrentIndexRef = useRef(0);

    const detailMediaSources = useRef([]);
    //const detailMediaSourcesPreload = useRef([]);

    const detailDescriptionLinks = useRef([]);
    const detailProductColors = useRef([]);
    const detailProductPrice = useRef();

    const [detailPlayButtonState, setDetailPlayButtonState] = useState(false);

    const [descriptionButtonFirstFillState, setDescriptionButtonFirstFillState] = useState("#333333");
    const [descriptionButtonSecondFillState, setDescriptionButtonSecondFillState] = useState("#333333");
    const [descriptionButtonThirdFillState, setDescriptionButtonThirdFillState] = useState("#333333");

    const [detailWidgetState, setDetailWidgetState] = useState(false);
    const [detailWidgetColorState, setDetailWidgetColorState] = useState("standard");
    const [detailWidgetButtonFillState, setDetailWidgetButtonFillState] = useState("#00000066");

    const [detailWidgetAddToCartCountState, setDetailWidgetAddToCartCountState] = useState(1);

    const {
        setCartContentState,
		setCheckoutPanelButtonState
    } = useContext(AppContext);

    const detailContent = useRef({
        title:
        "nils badertscher",

        text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt.",
    });

    const detailPlayButton =    <svg version="1.1" id="detail_play_button_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 96 96" preserveAspectRatio="xMidYMid meet" pointerEvents="none">
                                    <path id="play_bg" fill="#000000" opacity="0.75" d="M15,11.812c0-5.5,3.897-7.75,8.66-5L86.34,43c4.763,2.75,4.763,7.25,0,10L23.66,89.188c-4.763,2.75-8.66,0.5-8.66-5V11.812z"/>
                                    <path id="play" fill="#FFFFFF" d="M31,35.524c0-3.3,2.338-4.65,5.196-3L57.804,45c2.858,1.65,2.858,4.35,0,6L36.196,63.476c-2.858,1.65-5.196,0.3-5.196-3V35.524z"/>
                                </svg>;

    const detailDescriptionIcons = {
        steam_svg:

        <path id="steam" fill="#FFFFFF" d="M17.538,11.613c0,0.028,0,0.055,0.001,0.081l-4.014,5.826c-0.65-0.028-1.304,0.083-1.923,0.339
	        c-0.272,0.109-0.527,0.246-0.767,0.406l-8.814-3.627c0,0-0.205,3.353,0.644,5.851l6.231,2.57c0.313,1.397,1.272,2.621,2.688,3.214
	        c2.316,0.966,4.986-0.135,5.949-2.451c0.25-0.604,0.368-1.238,0.35-1.872l5.744-4.102c0.046,0.002,0.095,0.002,0.142,0.002
	        c3.437,0,6.231-2.8,6.231-6.237c0-3.438-2.794-6.238-6.231-6.238C20.333,5.375,17.538,8.175,17.538,11.613z M16.575,23.422
	        c-0.745,1.787-2.803,2.637-4.59,1.893c-0.824-0.344-1.447-0.973-1.807-1.725l2.028,0.842c1.32,0.549,2.83-0.076,3.379-1.393
	        c0.548-1.321-0.074-2.832-1.391-3.383l-2.098-0.869c0.81-0.306,1.729-0.316,2.588,0.041c0.867,0.361,1.538,1.039,1.896,1.904
	        C16.936,21.601,16.934,22.559,16.575,23.422 M23.77,15.77c-2.289,0-4.151-1.865-4.151-4.157c0-2.291,1.862-4.157,4.151-4.157
	        s4.152,1.866,4.152,4.157C27.922,13.905,26.059,15.77,23.77,15.77 M20.659,11.606c0-1.725,1.396-3.125,3.117-3.125
	        c1.723,0,3.119,1.4,3.119,3.125c0,1.725-1.396,3.122-3.119,3.122C22.056,14.729,20.659,13.331,20.659,11.606z"/>,

        discord_svg:

        <path id="discord" fill="#FFFFFF" d="M26.548,8.446c-3.452-2.59-6.735-2.516-6.735-2.516l-0.336,0.381
            c4.076,1.249,5.971,3.044,5.971,3.044c-2.494-1.367-4.939-2.037-7.216-2.301c-1.728-0.193-3.38-0.146-4.842,0.049
            c-0.144,0-0.263,0.023-0.407,0.05c-0.84,0.067-2.877,0.383-5.442,1.509c-0.889,0.41-1.416,0.695-1.416,0.695s1.99-1.892,6.304-3.139
            l-0.24-0.287c0,0-3.283-0.072-6.736,2.516c0,0-3.452,6.258-3.452,13.978c0,0,2.015,3.478,7.313,3.646c0,0,0.887-1.08,1.605-1.99
            c-3.044-0.911-4.195-2.828-4.195-2.828s0.24,0.166,0.671,0.406c0.024,0.023,0.047,0.049,0.095,0.073
            c0.073,0.048,0.146,0.068,0.216,0.119c0.599,0.335,1.199,0.597,1.75,0.814c0.983,0.38,2.159,0.766,3.523,1.028
            c1.798,0.334,3.907,0.457,6.208,0.023c1.127-0.193,2.277-0.526,3.477-1.031c0.84-0.311,1.773-0.766,2.758-1.414
            c0,0-1.2,1.966-4.34,2.853c0.719,0.91,1.582,1.943,1.582,1.943C27.963,25.899,30,22.422,30,22.422
            C30,14.702,26.548,8.446,26.548,8.446z M11.519,20.193c-1.342,0-2.444-1.199-2.444-2.658c0-1.466,1.077-2.663,2.444-2.663
            c1.368,0,2.469,1.198,2.444,2.663C13.962,18.994,12.885,20.193,11.519,20.193z M20.268,20.193c-1.342,0-2.444-1.199-2.444-2.658
            c0-1.466,1.077-2.663,2.444-2.663c1.369,0,2.445,1.198,2.445,2.663C22.712,18.994,21.636,20.193,20.268,20.193z"/>,

        xthrust_svg:

        <path id="xthrust" fill="#FFFFFF" d="M16,14.5c2.667,0,10.056-9.583,10.056-9.583H30V5.25C30,6.917,18.667,14.046,18.667,16
	        C18.667,17.953,30,25.084,30,26.75v0.334h-3.944c0,0-7.389-9.584-10.056-9.584S5.944,27.084,5.944,27.084H2V26.75
	        C2,25.084,13.333,17.953,13.333,16C13.333,14.046,2,6.917,2,5.25V4.917h3.944C5.944,4.917,13.333,14.5,16,14.5z"/>,

        cart_svg:

        <path id="cart" fill="#FFFFFF" d="M2,10.38l9.91,9.91c0.458,0.458,1.36,0.83,2.006,0.83h4.797c0.646,0,1.439-0.458,1.763-1.018
	        l5.614-9.723H2z M9.081,23.04c0,1.339-1.084,2.42-2.42,2.42s-2.42-1.081-2.42-2.42c0-1.336,1.084-2.42,2.42-2.42
	        S9.081,21.704,9.081,23.04z M25.933,23.04c0,1.338-1.084,2.42-2.42,2.42s-2.42-1.082-2.42-2.42c0-1.336,1.084-2.42,2.42-2.42
	        S25.933,21.704,25.933,23.04z M30,8.938l-2.213-2.212c-0.103-0.104-0.304-0.187-0.449-0.187h-1.071
	        c-0.144,0-0.321,0.102-0.394,0.227l-1.252,2.172H30z"/>,

        external_svg:

        <path id="external" fill="#FFFFFF" d="M24,18v4c0,1.103-0.897,2-2,2H6c-1.103,0-2-0.897-2-2v-8c0-1.103,0.897-2,2-2h12v-2H6
	        c-2.2,0-4,1.8-4,4v8c0,2.2,1.8,4,4,4h16c2.2,0,4-1.8,4-4v-4H24z M27.663,9.751l-1.414-1.414l-8.956,8.956l1.414,1.414L27.663,9.751z
	         M28.452,11.779L30,6l-5.779,1.548L28.452,11.779z"/>

    };

    function toggleVideoStatus() {

        if (mediaTopRef.current.tagName === "VIDEO") {
            if (mediaTopRef.current.paused) {
                mediaTopRef.current.play();
                setDetailPlayButtonState(false);
            } else {
                mediaTopRef.current.pause();
                setDetailPlayButtonState(true);
            };
        };

    };

    //Background media functions
    function getMediaElement(index, layer) {

        let mediaType = detailMediaSources.current[index].slice(-3);

        if (mediaType === "jpg" || mediaType === "png") {

            if (layer === "top") {

                return <img id="detail_media_top" alt={detailMediaSources.current[index].replaceAll("/media/", "")} src={detailMediaSources.current[index]} ref={mediaTopRef} />;

            } else {

                return <img id="detail_media_bottom" alt={detailMediaSources.current[index].replaceAll("/media/", "")} src={detailMediaSources.current[index]} ref={mediaBottomRef} />;

            };

        } else {

            if (layer === "top") {

                return  <video id="detail_media_top" ref={mediaTopRef} poster={detailMediaSources.current[index].replaceAll("mp4", "jpg")} playsInline>
                            <source src={detailMediaSources.current[index]} type="video/mp4" />
                        </video>;

            } else {

                return  <video id="detail_media_bottom" ref={mediaBottomRef} poster={detailMediaSources.current[index].replaceAll("mp4", "jpg")} playsInline>
                            <source src={detailMediaSources.current[index]} type="video/mp4" />
                        </video>;
                
            };

        };

    };

    function getMediaNextIndex() {

        //mediaCurrentRef + 1, if mediaCurrentRef + 1 > mediaCurrentRef.length - 1 => mediaCurrentRef = 0

        if (mediaCurrentIndexRef.current + 1 > detailMediaSources.current.length - 1) {

            return 0;

        } else {

            return mediaCurrentIndexRef.current + 1;

        };

    };

    function getMediaPreviousIndex() {

        //mediaCurrentRef - 1, if mediaCurrentRef - 1 < 0 => mediaCurrentRef = mediaCurrentRef.length

        if (mediaCurrentIndexRef.current - 1 < 0) {

            return detailMediaSources.current.length - 1;

        } else {

            return mediaCurrentIndexRef.current - 1;

        };

    };

    function goToNextMedia() {

        if (mediaFadeRunningRef.current === false) {

            mediaFadeRunningRef.current = true;
            mediaCurrentIndexRef.current = getMediaNextIndex();
            updateMedia("next");

        };

    };

    function goToPreviousMedia() {

        if (mediaFadeRunningRef.current === false) {

            mediaFadeRunningRef.current = true;
            mediaCurrentIndexRef.current = getMediaPreviousIndex();
            updateMedia("previous");

        };

    };

    function handleDetailItemClick(index) {

        if (mediaFadeRunningRef.current === false) {

            mediaFadeRunningRef.current = true;
            mediaCurrentIndexRef.current = index;
            updateMedia("next");
        
        };

    };

    function updateMedia(next) {

        //set new media on bottom layer

        setMediaBottom(getMediaElement(mediaCurrentIndexRef.current, "bottom"));

        //fade out top layer

        let detailMediaTopOpacity = 1;
        let detailMediaTopOpacityTarget = 0;
        let detailMediaTopAnimStep = -0.05;

        let intervalDetailMediaTopFadeOut = setInterval(detailMediaTopFadeOutInt, 5);
        function detailMediaTopFadeOutInt() {
            if (detailMediaTopOpacity !== detailMediaTopOpacityTarget) {
                detailMediaTopOpacity = Math.max(detailMediaTopOpacity + detailMediaTopAnimStep, detailMediaTopOpacityTarget);
                mediaTopRef.current.style.setProperty("opacity", detailMediaTopOpacity);
            } else {
                clearInterval(intervalDetailMediaTopFadeOut);

                //set new media on top layer

                setMediaTop(getMediaElement(mediaCurrentIndexRef.current, "top"));
        
                //show top layer

                setTimeout(function() {
                    mediaTopRef.current.style.setProperty("opacity", 1);
                    mediaFadeRunningRef.current = false;

                    //set next potential image on bottom layer

                    if (next === "next") {

                        setMediaBottom(getMediaElement(getMediaNextIndex(), "bottom"));

                    } else {

                        setMediaBottom(getMediaElement(getMediaPreviousIndex(), "bottom"));

                    };

                }, 30);

            };

        };

    };

    //Description buttons and detail widget functions
    function getDetailDescriptionIcon(buttonNr) {
        if (detailDescriptionLinks.current[buttonNr].includes("steampowered.com") || detailDescriptionLinks.current[buttonNr].includes("steamcommunity.com")) {
            return detailDescriptionIcons.steam_svg
        } else if (detailDescriptionLinks.current[buttonNr].includes("discord.com")) {
            return detailDescriptionIcons.discord_svg
        } else if (detailDescriptionLinks.current[buttonNr].includes("xthrust.com")) {
            return detailDescriptionIcons.xthrust_svg
        } else if (detailDescriptionLinks.current[buttonNr].includes("color") || detailDescriptionLinks.current[buttonNr].includes("quantity")) {
            return detailDescriptionIcons.cart_svg
        } else {
            return detailDescriptionIcons.external_svg
        };
    };

    function addItemToCart() {

        //add detailWidgetAddToCartCountState x itemRef.current to cart
        let cartContentTemp = [];

        if (!localStorage.getItem("cartContent")) {
            localStorage.setItem("cartContent", JSON.stringify(cartContentTemp));
        } else {
            cartContentTemp = JSON.parse(localStorage.getItem("cartContent"));
        };

        let color;

        if (detailWidgetColorState === "standard" && detailProductColors.current.length > 1) {
            color = detailProductColors.current[0];
        } else {
            color = detailWidgetColorState;
        };

        let foundItem = false;

        cartContentTemp.forEach((item, index) => {

            if (item.type === itemRef.current && item.color === color) {

                foundItem = true;
                item.count = item.count + detailWidgetAddToCartCountState;

            }

        });

        if (foundItem === false) {

            cartContentTemp.push({
                type: itemRef.current,
                color: color,
                price: detailProductPrice.current,
                count: detailWidgetAddToCartCountState
            });

        };

        localStorage.setItem("cartContent", JSON.stringify(cartContentTemp));
        setCartContentState(cartContentTemp);

        setCheckoutPanelButtonState(true);

        //reset add to cart count after transaction and hide detail widget
        setDetailWidgetAddToCartCountState(1);
        setDetailWidgetColorState("standard");
        setDetailWidgetState(false);

    }

    function detailWidgetCartNext() {

        if (detailWidgetState === "color") {

            setDetailWidgetState("quantity");

        } else if (detailWidgetState === "quantity") {

            addItemToCart();

        };

    };

    //Parameter dependent content
    if (!page || page === "about") {

        detailMediaSources.current = ["/media/njb_design_nils_badertscher.jpg"];

        detailContent.current.title = "nils badertscher";
        detailContent.current.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra.";

        detailDescriptionLinks.current = [];

    } else if (page === "cgi") {

        if (item === "opp2000" || (!item && itemRef.current === "opp2000") || (itemRef.current !== "opp2000" && itemRef.current !== "fabia" && itemRef.current !== "jetx" && itemRef.current !== "dw291")) {

            itemRef.current = "opp2000";

            detailMediaSources.current = ["/media/osterwalder_opp2000_0.mp4", "/media/osterwalder_opp2000_0.jpg"];

            detailContent.current.title = "opp 2000";
            detailContent.current.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt.";

            detailDescriptionLinks.current = ["https://www.osterwalder.com"];

        } else if (item === "fabia" || (!item && itemRef.current === "fabia")) {

            itemRef.current = "fabia";

            detailMediaSources.current = ["/media/meyerburger_fabia_0.mp4", "/media/meyerburger_fabia_0.jpg"];

            detailContent.current.title = "fabia";
            detailContent.current.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt.";

            detailDescriptionLinks.current = ["https://www.meyerburger.com"];

        } else if (item === "jetx" || (!item && itemRef.current === "jetx")) {

            itemRef.current = "jetx";

            detailMediaSources.current = ["/media/suss_jetx_m_0.mp4", "/media/suss_jetx_s_0.mp4", "/media/suss_jetx_m_1.jpg"];

            detailContent.current.title = "jet x";
            detailContent.current.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt.";

            detailDescriptionLinks.current = ["https://www.suss.com"];

        } else if (item === "dw291" || (!item && itemRef.current === "dw291")) {

            itemRef.current = "dw291";

            detailMediaSources.current = ["/media/peterwolters_dw291_0.mp4", "/media/peterwolters_dw291_0.jpg"];

            detailContent.current.title = "dw 291";
            detailContent.current.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt.";

            detailDescriptionLinks.current = ["https://www.peter-wolters.com"];

        };

    } else if (page === "games") {

        if (item === "xthrust" || (!item && itemRef.current === "xthrust") || (itemRef.current !== "xthrust" && itemRef.current !== "ammomaker")) {

            itemRef.current = "xthrust";

            detailMediaSources.current = ["/media/njb_design_xthrust_0.mp4", "/media/njb_design_xthrust_1.jpg", "/media/njb_design_xthrust_2.jpg"];

            detailContent.current.title = "xthrust";
            detailContent.current.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt.";

            detailDescriptionLinks.current = ["https://xthrust.com", "https://store.steampowered.com/app/1527080/XTHRUST/", "https://discord.com/invite/jgPeNnk"];

        } else if (item === "ammomaker" || (!item && itemRef.current === "ammomaker")) {

            itemRef.current = "ammomaker";

            detailMediaSources.current = ["/media/njb_design_xthrust_1.jpg", "/media/peterwolters_dw291_0.jpg"];

            detailContent.current.title = "ammo maker";
            detailContent.current.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt.";

            detailDescriptionLinks.current = ["https://steamcommunity.com/sharedfiles/filedetails/?id=2788256295", "https://discord.com/invite/GatzBH78Rj"];

        };

    } else if (page === "design") {

        if (item === "curve" || (!item && itemRef.current === "curve") || (itemRef.current !== "curve" && itemRef.current !== "wristband")) {

            itemRef.current = "curve";

            detailMediaSources.current = ["/curve_0.jpg", "/media/peterwolters_dw291_0.jpg"];

            detailContent.current.title = "curve";
            detailContent.current.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt.";

            detailDescriptionLinks.current = [];

        } else if (item === "wristband" || (!item && itemRef.current === "wristband")) {

            itemRef.current = "wristband";

            detailMediaSources.current = ["/media/njb_design_wristband_0.jpg", "/media/njb_design_wristband_1.jpg", "/media/njb_design_wristband_2.jpg"];

            detailContent.current.title = "wristband";
            detailContent.current.text = "Conception and design of the xthrust wristband prototype. Metal parts 3D printed in wax and cast in platinum, combined with lava stone pearls.";

            detailDescriptionLinks.current = [];

        };

    } else if (page === "store") {

        if (item === "njb_design_sticker" || (!item && itemRef.current === "njb_design_sticker") || (itemRef.current !== "njb_design_sticker" && itemRef.current !== "ammo_maker_sticker" && itemRef.current !== "xthrust_sticker")) {

            itemRef.current = "njb_design_sticker";
            detailProductColors.current = ["standard"];
            detailProductPrice.current = 5.99;

            detailMediaSources.current = ["/Curve_rgb.jpg", "/media/peterwolters_dw291_0.jpg"];

            detailContent.current.title = "njb design sticker";
            detailContent.current.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra.";

            detailDescriptionLinks.current = ["quantity"];

        } else if (item === "ammo_maker_sticker" || (!item && itemRef.current === "ammo_maker_sticker")) {

            itemRef.current = "ammo_maker_sticker";
            detailProductColors.current = ["silver", "black"];
            detailProductPrice.current = 5.99;

            detailMediaSources.current = ["/media/njb_design_wristband_2.jpg", "/media/peterwolters_dw291_0.jpg"];

            detailContent.current.title = "ammo maker sticker";
            detailContent.current.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra.";

            detailDescriptionLinks.current = ["color"];

        } else if (item === "xthrust_sticker" || (!item && itemRef.current === "xthrust_sticker")) {

            itemRef.current = "xthrust_sticker";
            detailProductColors.current = ["silver", "black"];
            detailProductPrice.current = 5.99;

            detailMediaSources.current = ["/media/njb_design_wristband_2.jpg", "/media/peterwolters_dw291_0.jpg"];

            detailContent.current.title = "xthrust sticker";
            detailContent.current.text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacus diam, efficitur eget nibh quis. Sed sit amet lacus sit amet tellus tincidunt. Vestibulum vestibulum nisi vel elit blandit pharetra.";

            detailDescriptionLinks.current = ["color"];

        };

    };

    if (mediaCurrentIndexRef.current + 1 > detailMediaSources.current.length) {

        mediaCurrentIndexRef.current = 0;

    };

    const [mediaTop, setMediaTop] = useState(getMediaElement(mediaCurrentIndexRef.current, "top"));
    const [mediaBottom, setMediaBottom] = useState(getMediaElement(getMediaNextIndex(), "bottom"));

    //pointer interaction
    let touchStartX = 0;
    let touchCurrentX = 0;
    let touchEndX = 0;
    let touchStartY = 0;
    let touchCurrentY = 0;
    let touchEndY = 0;
        
    function checkDirection() {
      if (touchEndX < touchStartX && touchStartX - touchEndX > 75) goToNextMedia();
      if (touchEndX > touchStartX && touchEndX - touchStartX > 75) goToPreviousMedia();
    }

    function checkDirectionX(startX, endX, startY, endY) {
        let distanceX = 0;
        let distanceY = 0;

        if (startX > endX) {
            distanceX = startX - endX;
        } else {
            distanceX = endX - startX;
        };
            
        if (startY > endY) {
            distanceY = startY - endY;
        } else {
            distanceY = endY - startY;
        };
            
        if (distanceX > distanceY) {
            return true;
        } else {
            return false;
        };

    };

    function pointerDown(e) {
        setDetailWidgetState(false);
    };

    //touch interaction
    function touchStart(e) {
        setDetailWidgetState(false);
        touchStartX = e.changedTouches[0].screenX;
        touchStartY = e.changedTouches[0].screenY;
    };

    function touchMove(e) {
        touchCurrentX = e.changedTouches[0].screenX;
        touchCurrentY = e.changedTouches[0].screenY;
        if (checkDirectionX(touchStartX, touchCurrentX, touchStartY, touchCurrentY)) {
            document.body.style.overflow = 'hidden';
        };
    };

    function touchEnd(e) {
        touchEndX = e.changedTouches[0].screenX;
        touchEndY = e.changedTouches[0].screenY;
        document.body.style.overflow = 'visible';
        if (checkDirectionX(touchStartX, touchEndX, touchStartY, touchEndY)) {
            checkDirection();
        };
    };

    function handleScroll() {

        setDetailWidgetState(false);

        if (mediaTopRef.current.tagName === "VIDEO" && !mediaTopRef.current.paused) {
            toggleVideoStatus();
        };

    };

    //useEffect on initial render
    useEffect(() => {

        window.addEventListener("scroll", handleScroll);

        return () => {

            window.removeEventListener("scroll", handleScroll);

        };
                                                                    // eslint-disable-next-line
    }, []);

    //useEffect on page parameter or itemRef change
    useEffect(() => {

        mediaCurrentIndexRef.current = 0;
        setMediaTop(getMediaElement(mediaCurrentIndexRef.current, "top"));
        setMediaBottom(getMediaElement(getMediaNextIndex(), "bottom"));
        setDetailWidgetState(false);
                                                                                                                                                // eslint-disable-next-line
    }, [itemRef.current, page]);

    useEffect(() => {
        if (mediaTopRef.current.tagName === "VIDEO") {
            setDetailPlayButtonState(true);
            mediaTopRef.current.load();
        } else {
            setDetailPlayButtonState(false);
        };
                                                                                                                                                // eslint-disable-next-line
    }, [mediaTop]);

    return (
        <div id="detail_container">
            <div id="detail_container_content" ref={detailContainerRef} onClick={toggleVideoStatus} onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd} onPointerDown={pointerDown}>
                {mediaBottom}
                {mediaTop}
                {detailPlayButtonState === true ? detailPlayButton : null}
            </div>
            <div id="description_container">
                <svg version="1.1" id="description_bg_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 450 280" preserveAspectRatio="xMaxYMin meet" pointerEvents="none">
                    <path id="description_bg" fill="#000000" opacity="0.75" d="M450,280V14H210.65c-3.85,0-8.573,2.729-10.5,6.063L50.075,280H450z" pointerEvents="auto"/>
                </svg>
                <div id="description_shape_left"></div>
                <div id="description_shape_right"></div>
                <div id="description_title">{detailContent.current.title}</div>
                <div id="description_text">{detailContent.current.text}</div>
                <svg version="1.1" id="description_buttons_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 450 280" preserveAspectRatio="xMaxYMin meet" pointerEvents="none">
                    {(() => {
                        if (detailDescriptionLinks.current.length === 0) {
                            return (
                                <g>
                                    <path id="button_bar_none" opacity="0.75" fill="#333333" d="M50.075,280L194.413,30h-41c-3.852,0-8.575,2.729-10.501,6.063L2.057,280H50.075z"/>
                                </g>
                            )
                        } else if (detailDescriptionLinks.current.length === 1) {
                            return (
                                <g>
                                    <path id="button_bar_one" opacity="0.75" fill="#333333" d="M50.075,280L165.546,80h-48.004L2.057,280H50.075z"/>
                                    <path id="description_button_first" opacity="0.75" fill={descriptionButtonFirstFillState} d="M166.738,77.936L194.413,30h-41c-3.852,0-8.575,2.729-10.501,6.063L118.696,78L166.738,77.936z" pointerEvents="auto" onPointerDown={() => {setDescriptionButtonFirstFillState("#B2B2B2")}} onPointerUp={() => {setDescriptionButtonFirstFillState("#333333")}} onPointerLeave={() => {setDescriptionButtonFirstFillState("#333333")}} onTouchStart={() => {setDescriptionButtonFirstFillState("#B2B2B2")}} onTouchEnd={() => {setDescriptionButtonFirstFillState("#333333")}} onClick={() => {setDetailWidgetState(detailDescriptionLinks.current[0])}}/>
                                    <svg x="140.554px" y="38px" width="32px" height="32px" viewBox="0 0 32 32">
                                        {getDetailDescriptionIcon(0)}
                                    </svg>
                                </g>
                            )
                        } else if (detailDescriptionLinks.current.length === 2) {
                            return (
                                <g>
                                    <path id="button_bar_two" opacity="0.75" fill="#333333" d="M50.075,280l86.603-150H88.671L2.057,280H50.075z"/>
                                    <path id="description_button_first" opacity="0.75" fill={descriptionButtonFirstFillState} d="M166.738,77.936L194.413,30h-41c-3.852,0-8.575,2.729-10.501,6.063L118.696,78L166.738,77.936z" pointerEvents="auto" onPointerDown={() => {setDescriptionButtonFirstFillState("#B2B2B2")}} onPointerUp={() => {setDescriptionButtonFirstFillState("#333333")}} onPointerLeave={() => {setDescriptionButtonFirstFillState("#333333")}} onTouchStart={() => {setDescriptionButtonFirstFillState("#B2B2B2")}} onTouchEnd={() => {setDescriptionButtonFirstFillState("#333333")}} onClick={() => {setDetailWidgetState(detailDescriptionLinks.current[0])}}/>
                                    <svg x="140.554px" y="38px" width="32px" height="32px" viewBox="0 0 32 32">
                                        {getDetailDescriptionIcon(0)}
                                    </svg>
                                    <path id="description_button_second" opacity="0.75" fill={descriptionButtonSecondFillState} d="M137.833,128l27.713-48h-48.004l-27.717,48H137.833z" pointerEvents="auto" onPointerDown={() => {setDescriptionButtonSecondFillState("#B2B2B2")}} onPointerUp={() => {setDescriptionButtonSecondFillState("#333333")}} onPointerLeave={() => {setDescriptionButtonSecondFillState("#333333")}} onTouchStart={() => {setDescriptionButtonSecondFillState("#B2B2B2")}} onTouchEnd={() => {setDescriptionButtonSecondFillState("#333333")}} onClick={() => {setDetailWidgetState(detailDescriptionLinks.current[1])}}/>
                                    <svg x="111.687px" y="88px" width="32px" height="32px" viewBox="0 0 32 32">
                                        {getDetailDescriptionIcon(1)}
                                    </svg>
                                </g>
                            )
                        } else if (detailDescriptionLinks.current.length === 3) {
                            return (
                                <g>
                                    <path id="description_button_bar_three" opacity="0.75" fill="#333333" d="M50.075,280l57.735-100H59.8L2.057,280H50.075z"/>
                                    <path id="description_button_first" opacity="0.75" fill={descriptionButtonFirstFillState} d="M166.738,77.936L194.413,30h-41c-3.852,0-8.575,2.729-10.501,6.063L118.696,78L166.738,77.936z" pointerEvents="auto" onPointerDown={() => {setDescriptionButtonFirstFillState("#B2B2B2")}} onPointerUp={() => {setDescriptionButtonFirstFillState("#333333")}} onPointerLeave={() => {setDescriptionButtonFirstFillState("#333333")}} onTouchStart={() => {setDescriptionButtonFirstFillState("#B2B2B2")}} onTouchEnd={() => {setDescriptionButtonFirstFillState("#333333")}} onClick={() => {setDetailWidgetState(detailDescriptionLinks.current[0])}}/>
                                    <svg x="140.554px" y="38px" width="32px" height="32px" viewBox="0 0 32 32">
                                        {getDetailDescriptionIcon(0)}
                                    </svg>
                                    <path id="description_button_second" opacity="0.75" fill={descriptionButtonSecondFillState} d="M137.833,128l27.713-48h-48.004l-27.717,48H137.833z" pointerEvents="auto" onPointerDown={() => {setDescriptionButtonSecondFillState("#B2B2B2")}} onPointerUp={() => {setDescriptionButtonSecondFillState("#333333")}} onPointerLeave={() => {setDescriptionButtonSecondFillState("#333333")}} onTouchStart={() => {setDescriptionButtonSecondFillState("#B2B2B2")}} onTouchEnd={() => {setDescriptionButtonSecondFillState("#333333")}} onClick={() => {setDetailWidgetState(detailDescriptionLinks.current[1])}}/>
                                    <svg x="111.687px" y="88px" width="32px" height="32px" viewBox="0 0 32 32">
                                        {getDetailDescriptionIcon(1)}
                                    </svg>
                                    <path id="description_button_third" opacity="0.75" fill={descriptionButtonThirdFillState} d="M108.965,178l27.713-48H88.671l-27.717,48H108.965z" pointerEvents="auto" onPointerDown={() => {setDescriptionButtonThirdFillState("#B2B2B2")}} onPointerUp={() => {setDescriptionButtonThirdFillState("#333333")}} onPointerLeave={() => {setDescriptionButtonThirdFillState("#333333")}} onTouchStart={() => {setDescriptionButtonThirdFillState("#B2B2B2")}} onTouchEnd={() => {setDescriptionButtonThirdFillState("#333333")}} onClick={() => {setDetailWidgetState(detailDescriptionLinks.current[2])}}/>
                                    <svg x="82.819px" y="138px" width="32px" height="32px" viewBox="0 0 32 32">
                                        {getDetailDescriptionIcon(2)}
                                    </svg>
                                </g>
                            )
                        }
                    })()}
                </svg>
            </div>
            {detailMediaSources.current.length > 1 ? 
                <div id="detail_controls_side_container">
                    <svg version="1.1" id="detail_controls_left_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" preserveAspectRatio="xMinYMid meet" pointerEvents="none">
                        <path id="detail_controls_left" fill="#FFFFFF" d="M16.825,22.003c-1.1,1.101-1.1,2.896,0,3.995l14.079,14.079c1.098,1.1,1.428,0.857,0.734-0.531l-6.514-13.02c-0.691-1.391-0.691-3.664,0-5.052l6.514-13.022c0.69-1.39,0.361-1.628-0.734-0.526L16.825,22.003z"/>
                        <rect id="detail_controls_left_button" opacity="0" x="0" y="0" width="48" height="48" pointerEvents="auto" onClick={() => {goToPreviousMedia()}}/>
                    </svg>
                    <svg version="1.1" id="detail_controls_right_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" preserveAspectRatio="xMaxYMid meet" pointerEvents="none">
                        <path id="detail_controls_right" fill="#FFFFFF" d="M31.175,25.997c1.1-1.1,1.1-2.896,0-3.995L17.096,7.923c-1.098-1.1-1.428-0.857-0.734,0.531l6.514,13.02c0.691,1.391,0.691,3.664,0,5.051l-6.514,13.022c-0.69,1.39-0.361,1.628,0.734,0.526L31.175,25.997z"/>
                        <rect id="detail_controls_right_button" opacity="0" x="0" y="0" width="48" height="48" pointerEvents="auto" onClick={() => {goToNextMedia()}}/>
                    </svg>
                </div>
            : null}
            {detailMediaSources.current.length > 1 ? 
            <div id="detail_controls_items_container">
                {
                    detailMediaSources.current.map((value, index)=>{
                        return(
                            <svg version="1.1" key={index} id="detail_controls_item_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" pointerEvents="none">
                                <rect id="detail_controls_item" opacity="0" x="0" y="0" width="20" height="20" pointerEvents="auto" onClick={() => {handleDetailItemClick(index)}}/>
                                <circle id="detail_controls_item_dot" fill="#FFFFFF" cx="10" cy="10" r={mediaCurrentIndexRef.current === index ? "4" : "2"}/>
                            </svg>
                        )
                    })
                }
            </div>
            : null}
            {detailWidgetState !== false ? 
                <div id="detail_widget">
                    <div id="detail_widget_content">
                        <svg version="1.1" id="detail_widget_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 480 192" preserveAspectRatio="xMinYMin meet" pointerEvents="none">
                            <path id="detail_widget_bg" fill="#000000" opacity="0.75" d="M414.59,191.998c5.5,0,12.25-3.928,15-8.689L475,104.66c2.75-4.764,5-13.16,5-18.66V10c0-5.5-4.5-9.999-10-10H65.454c-5.5-0.001-12.25,3.848-15,8.611L5,87.34C2.25,92.103,0,100.5,0,106v75.998c0,5.5,4.5,10.001,10,10H414.59z" pointerEvents="auto"/>
                            <rect id="detail_widget_close_bg" x="444" fill="#000000" opacity="0" width="36" height="36" pointerEvents="auto" onClick={() => {setDetailWidgetState(false)}}/>
                            <polygon id="detail_widget_close_icon" fill="#FFFFFF" points="456.938,24.75 462,19.688 467.063,24.75 468.75,23.063 463.688,18 468.75,12.938 467.063,11.25 462,16.313 456.938,11.25 455.25,12.938 460.313,18 455.25,23.063 " pointerEvents="none"/>
                        </svg>
                        {(() => {
                            if (detailWidgetState === "color") {
                                return (
                                    <div id="detail_widget_text">
                                        <div id="detail_widget_text_title">select color</div>
                                        <div id="detail_widget_color_container">
                                            {
                                                detailProductColors.current.map((value, index)=>{
                                                    return(
                                                        <svg version="1.1" key={index} id="detail_widget_color_field_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36" pointerEvents="none">
                                                            <defs>
                                                                <linearGradient id="gradient_silver" gradientTransform="rotate(45) translate(0.2, 0.2)">
	                                                                <stop offset="0%" stopColor="#6D6D6D"/>
	                                                                <stop offset="50%" stopColor="#C8C8C8"/>
	                                                                <stop offset="100%" stopColor="#6D6D6D"/>
                                                                </linearGradient>
                                                            </defs>
                                                            <circle id="detail_widget_color_field_bg" fill="#333333" cx="18" cy="18" r="18"/>
                                                            <circle id="detail_widget_color_field_bg" fill={(() => {
                                                                if (value === "silver") {
                                                                    return "url('#gradient_silver')"
                                                                } else if (value === "black") {
                                                                    return "#222222"
                                                                } else if (value === "lime") {
                                                                    return "#CCFF33"
                                                                };
                                                            })()} pointerEvents="auto" onClick = {() => {setDetailWidgetColorState(value)}} cx="18" cy="18" r="16"/>
                                                            {value === detailWidgetColorState || (detailWidgetColorState === "standard" && index === 0) ? <path id="detail_widget_checkmark" fill="#FFFFFF" d="M16.813,26.26c-0.283,0-0.616-0.096-0.818-0.299l-6.347-6.348l1.517-1.517l5.358,5.359l7.974-13.808l1.857,1.073l-8.672,15.018C17.515,26.027,17.078,26.26,16.813,26.26z"/> : null}
                                                        </svg>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div id="detail_widget_button_container">
                                            <div    id="detail_widget_button"
                                                    style={{backgroundColor: detailWidgetButtonFillState}}
                                                    onClick={() => {detailWidgetCartNext()}}
                                                    onPointerDown={() => {setDetailWidgetButtonFillState("#66666666")}}
                                                    onTouchStart={() => {setDetailWidgetButtonFillState("#66666666")}}
                                                    onPointerUp={() => {setDetailWidgetButtonFillState("#00000066")}}
                                                    onPointerLeave={() => {setDetailWidgetButtonFillState("#00000066")}}
                                                    onTouchEnd={() => {setDetailWidgetButtonFillState("#00000066")}}
                                            >
                                                <div id="detail_widget_text_button">next</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (detailWidgetState === "quantity") {
                                return (
                                    <div id="detail_widget_text">
                                        <div id="detail_widget_text_title">select quantity</div>
                                        <div id="detail_widget_text_cart_amount">{detailWidgetAddToCartCountState}</div>
                                        <svg version="1.1" id="detail_widget_minus_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36" pointerEvents="none">
                                            <rect id="detail_widget_minus_bg" fill="#000000" opacity="0" width="36" height="36" pointerEvents="auto" onClick = {() => {setDetailWidgetAddToCartCountState(Math.max(detailWidgetAddToCartCountState - 1, 1))}}/>
                                            <rect id="detail_widget_minus_icon" x="9.647" y="16.807" fill="#FFFFFF" width="16.705" height="2.387"/>
                                        </svg>
                                        <svg version="1.1" id="detail_widget_plus_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36" pointerEvents="none">
                                            <rect id="detail_widget_plus_bg" fill="#000000" opacity="0" width="36" height="36" pointerEvents="auto" onClick = {() => {setDetailWidgetAddToCartCountState(Math.min(detailWidgetAddToCartCountState + 1, 99))}}/>
                                            <polygon id="detail_widget_plus_icon" fill="#FFFFFF" points="26.353,19.193 26.353,16.807 19.193,16.807 19.193,9.647 16.807,9.647 16.808,16.807 9.647,16.807 9.647,19.193 16.807,19.193 16.807,26.353 19.193,26.353 19.193,19.193 "/>
                                        </svg>
                                        <div id="detail_widget_button_container">
                                            <div    id="detail_widget_button"
                                                    style={{backgroundColor: detailWidgetButtonFillState}}
                                                    onClick={() => {detailWidgetCartNext()}}
                                                    onPointerDown={() => {setDetailWidgetButtonFillState("#66666666")}}
                                                    onTouchStart={() => {setDetailWidgetButtonFillState("#66666666")}}
                                                    onPointerUp={() => {setDetailWidgetButtonFillState("#00000066")}}
                                                    onPointerLeave={() => {setDetailWidgetButtonFillState("#00000066")}}
                                                    onTouchEnd={() => {setDetailWidgetButtonFillState("#00000066")}}
                                            >
                                                <div id="detail_widget_text_button">add to cart</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (detailWidgetState.includes("https://")) {
                                return (
                                    <div id="detail_widget_text">
                                        {(() => {
                                            if (detailWidgetState.includes("steampowered.com") || detailWidgetState.includes("steamcommunity.com")) {
                                                return (<div id="detail_widget_text_title">get {detailContent.current.title} on steam</div>)
                                            } else if (detailWidgetState.includes("discord.com")) {
                                                return (<div id="detail_widget_text_title">join {detailContent.current.title} on discord</div>)
                                            } else if (detailWidgetState.includes("xthrust.com")) {
                                                return (<div id="detail_widget_text_title">get {detailContent.current.title}</div>)
                                            } else {
                                                return (<div id="detail_widget_text_title">open external url</div>)
                                            }
                                        })()}
                                        <div id="detail_widget_text_external_url">{detailWidgetState}</div>
                                        <div id="detail_widget_button_container">
                                            <a      id="detail_widget_button" 
                                                    href={detailWidgetState}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{backgroundColor: detailWidgetButtonFillState}}
                                                    onClick={() => {setDetailWidgetState(false)}}
                                                    onPointerDown={() => {setDetailWidgetButtonFillState("#66666666")}}
                                                    onTouchStart={() => {setDetailWidgetButtonFillState("#66666666")}}
                                                    onPointerUp={() => {setDetailWidgetButtonFillState("#00000066")}}
                                                    onPointerLeave={() => {setDetailWidgetButtonFillState("#00000066")}}
                                                    onTouchEnd={() => {setDetailWidgetButtonFillState("#00000066")}}
                                            >
                                                <div id="detail_widget_text_button">open url</div>
                                            </a>
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                </div>
            : null}
        </div>
    );

};

/*<div id="detail_preload_container">
    { 
        detailMediaSourcesPreload.current.map((value, index)=>{
            return(
                <img id="detail_preload_media" key={index} alt="" src={value}/>
            )
        })
    }
</div>*/