import { useLocation } from 'react-router-dom';

export default function Error() {

    const location = useLocation();

    if (location.pathname === "/404") {
        document.title = "404 - njb design®";
    } else if (location.pathname === "/500") {
        document.title = "500 - njb design®";
    }

    return (
        <main id="error">
            <img id="media" alt="" src="/xt_3.jpg"/>
            <div id="error_container">
                {(() => {
                    if (location.pathname === "/404") {
                        return (
                            <div id="error_text">
                                <h1>404</h1>
                                <h2>not found</h2>
                            </div>
                        )
                    } else if (location.pathname === "/500") {
                        return (
                            <div id="error_text">
                                <h1>500</h1>
                                <h2>internal server error</h2>
                            </div>
                        )
                    }
                })()}
            </div>
        </main>
    );

};