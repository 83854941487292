import './style/css/app.css';

import { useState, useRef } from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AppContext } from './scripts/context'

import Header from './components/header'
import Footer from './components/footer'
import Page from './components/page'
import Error from './components/error'

function App() {

    const [cartContentState, setCartContentState] = useState(JSON.parse(localStorage.getItem("cartContent")));
    const [checkoutPanelState, setCheckoutPanelState] = useState(false);
    const [checkoutPanelContentState, setCheckoutPanelContentState] = useState("cart");
    const [checkoutPanelCountryState, setCheckoutPanelCountryState] = useState("select_country");
    const [checkoutPanelButtonState, setCheckoutPanelButtonState] = useState(!cartContentState ? false : true);
    const [paypalSuccessState, setPaypalSuccessState] = useState(false);

    const menuExpandedRef = useRef(true);
    const menuRunningRef = useRef(false);
    const menuLockedRef = useRef(false);
    const menuElementsRefs = {
        pathHome: useRef(null),
        circleHome0: useRef(null),
        circleHome1: useRef(null),
        textHomeH: useRef(null),
        textHomeO: useRef(null),
        textHomeM: useRef(null),
        textHomeE: useRef(null),
        linkHome: useRef(null),
        pathCgi: useRef(null),
        circleCgi0: useRef(null),
        circleCgi1: useRef(null),
        textCgiC: useRef(null),
        textCgiG: useRef(null),
        textCgiI: useRef(null),
        linkCgi: useRef(null),
        pathGames: useRef(null),
        circleGames0: useRef(null),
        circleGames1: useRef(null),
        textGamesG: useRef(null),
        textGamesA: useRef(null),
        textGamesM: useRef(null),
        textGamesE: useRef(null),
        textGamesS: useRef(null),
        linkGames: useRef(null),
        pathDesign: useRef(null),
        circleDesign0: useRef(null),
        circleDesign1: useRef(null),
        textDesignD: useRef(null),
        textDesignE: useRef(null),
        textDesignS: useRef(null),
        textDesignI: useRef(null),
        textDesignG: useRef(null),
        textDesignN: useRef(null),
        linkDesign: useRef(null),
        pathStore: useRef(null),
        circleStore0: useRef(null),
        circleStore1: useRef(null),
        textStoreS: useRef(null),
        textStoreT: useRef(null),
        textStoreO: useRef(null),
        textStoreR: useRef(null),
        textStoreE: useRef(null),
        linkStore: useRef(null),
        buttonMenu: useRef(null),
        menuBg: useRef(null)
    };
    const pageElementsRefs = {
        mainContainerContent: useRef(null),
        bodyElement: useRef(document.getElementsByTagName("body")[0])
    };

    const scrollPrevPosRef = useRef();

    function setMenuLocked(value) {
        menuLockedRef.current = value;
    };

    function scrollToTitle(scrollBehavior) {

        menuLockedRef.current = true;

        let intervalToTitleMenu = setInterval(lockToTitleMenu, 25);
        function lockToTitleMenu() {
            if (getScrollPosition() > 0.05) {
                clearInterval(intervalToTitleMenu);
                menuLockedRef.current = false;
                handleMenu("scroll");
            };
        };

        window.scrollTo({
            top: getFadeEndTopPixel(),
            left: 0,
            behavior: scrollBehavior,
        });
    };

    function scrollToOverview(scrollBehavior) {

        menuLockedRef.current = true;

        let intervalToOverviewMenu = setInterval(lockToOverviewMenu, 25);
        function lockToOverviewMenu() {
            if (getScrollPosition() > 0.05) {
                clearInterval(intervalToOverviewMenu);
                menuLockedRef.current = false;
                handleMenu("scroll");
            };
        };

        window.scrollTo({
            top: getFadeStartBottomPixel(),
            left: 0,
            behavior: scrollBehavior,
        });

    };

    function scrollToTop(scrollBehavior) {

        menuLockedRef.current = true;

        let intervalToTop = setInterval(lockToTop, 25);
        function lockToTop() {
            if (getScrollPosition() < 0.05) {
                clearInterval(intervalToTop);
                menuLockedRef.current = false;
                handleMenu("scroll");
            };
        };

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: scrollBehavior,
        });

    };

    function scrollToBottom(scrollBehavior) {

        menuLockedRef.current = true;

        let intervalToBottom = setInterval(lockToBottom, 25);
        function lockToBottom() {
            if (getScrollPosition() > 0.05) {
                clearInterval(intervalToBottom);
                menuLockedRef.current = false;
                handleMenu("scroll");
            };
        };

        window.scrollTo({
            top: (document.body.scrollHeight - window.innerHeight),
            left: 0,
            behavior: scrollBehavior,
        });

    };

    function getScrollPosition() {

        let scrollPos = (window.scrollY/(document.body.scrollHeight - window.innerHeight));

        if (scrollPos < 0.01) {
            scrollPos = 0;
        } else if (scrollPos > 0.99) {
            scrollPos = 1;
        };

        return scrollPos;

    };

    function getFadeEndTop() {

        return (1 / document.body.scrollHeight * window.innerHeight);

    };

    function getFadeStartBottom() {

        return (1 / document.body.scrollHeight * (document.body.scrollHeight - window.innerHeight));

    };

    function getFadeEndTopPixel() {

        return (getFadeEndTop() * (document.body.scrollHeight - window.innerHeight));

    };

    function getFadeStartBottomPixel() {

        return (getFadeStartBottom() * (document.body.scrollHeight - window.innerHeight));

    };

    function handleMenu(type) {

        if (menuRunningRef.current === false && menuLockedRef.current === false) {

            menuRunningRef.current = true;

            const animSpeed = 7;
            const animDeltaTime = 5;

            let scrollPos = getScrollPosition();

            function expandMenu() {

                let menuBgOpacity = 0;
                let intervalMenuBgOpacity = setInterval(offsetCircleHome, animDeltaTime);
                function offsetCircleHome() {
                    if (menuBgOpacity < 0.75) {
                        menuBgOpacity = Math.min(menuBgOpacity + 0.025, 0.75);
                        menuElementsRefs.menuBg.current.setAttribute("opacity", menuBgOpacity);
                    } else {
                        clearInterval(intervalMenuBgOpacity);
                    };
                };

                menuElementsRefs.buttonMenu.current.setAttribute("display", "none");

                function setHomeVisible() {
                    setTimeout(function() {
                        menuElementsRefs.textHomeH.current.setAttribute("visibility", "visible");
                    }, 30);
                    setTimeout(function() {
                        menuElementsRefs.textHomeO.current.setAttribute("visibility", "visible");
                    }, 60);
                    setTimeout(function() {
                        menuElementsRefs.textHomeM.current.setAttribute("visibility", "visible");
                    }, 90);
                    setTimeout(function() {
                        menuElementsRefs.textHomeE.current.setAttribute("visibility", "visible");
                        menuElementsRefs.linkHome.current.setAttribute("visibility", "visible");
                    }, 120);
                };

                let pathHomeOffset = 169;
                let intervalHome = setInterval(offsetHome, animDeltaTime);
                function offsetHome() {
                    if (pathHomeOffset > 0) {
                        pathHomeOffset = Math.max(pathHomeOffset - animSpeed, 0);
                        menuElementsRefs.pathHome.current.setAttribute("stroke-dashoffset", pathHomeOffset);
                    } else {
                        clearInterval(intervalHome);
                        setHomeVisible();

                        let circleHomeOffset = 13;
                        let intervalHomeCircle = setInterval(offsetHomeCircle, animDeltaTime);
                        function offsetHomeCircle() {
                            if (circleHomeOffset > 0) {
                                circleHomeOffset = Math.max(circleHomeOffset - animSpeed, 0);
                                menuElementsRefs.circleHome0.current.setAttribute("stroke-dashoffset", circleHomeOffset);
                                menuElementsRefs.circleHome1.current.setAttribute("stroke-dashoffset", circleHomeOffset);
                            } else {
                                clearInterval(intervalHomeCircle);
                            };
                        };
                    };
                };

                function setCgiVisible() {
                    setTimeout(function() {
                        menuElementsRefs.textCgiC.current.setAttribute("visibility", "visible");
                    }, 30);
                    setTimeout(function() {
                        menuElementsRefs.textCgiG.current.setAttribute("visibility", "visible");
                    }, 60);
                    setTimeout(function() {
                        menuElementsRefs.textCgiI.current.setAttribute("visibility", "visible");
                        menuElementsRefs.linkCgi.current.setAttribute("visibility", "visible");
                    }, 90);
                };

                let pathCgiOffset = 152;
                let intervalCgi = setInterval(offsetCgi, animDeltaTime);
                function offsetCgi() {
                    if (pathCgiOffset > 0) {
                        pathCgiOffset = Math.max(pathCgiOffset - animSpeed, 0);
                        menuElementsRefs.pathCgi.current.setAttribute("stroke-dashoffset", pathCgiOffset);
                    } else {
                        clearInterval(intervalCgi);
                        setCgiVisible();

                        let circleCgiOffset = 13;
                        let intervalCgiCircle = setInterval(offsetCgiCircle, animDeltaTime);
                        function offsetCgiCircle() {
                            if (circleCgiOffset > 0) {
                                circleCgiOffset = Math.max(circleCgiOffset - animSpeed, 0);
                                menuElementsRefs.circleCgi0.current.setAttribute("stroke-dashoffset", circleCgiOffset);
                                menuElementsRefs.circleCgi1.current.setAttribute("stroke-dashoffset", circleCgiOffset);
                            } else {
                                clearInterval(intervalCgiCircle);
                            };
                        };
                    };
                };

                function setGamesVisible() {
                    setTimeout(function() {
                        menuElementsRefs.textGamesG.current.setAttribute("visibility", "visible");
                    }, 30);
                    setTimeout(function() {
                        menuElementsRefs.textGamesA.current.setAttribute("visibility", "visible");
                    }, 60);
                    setTimeout(function() {
                        menuElementsRefs.textGamesM.current.setAttribute("visibility", "visible");
                    }, 90);
                    setTimeout(function() {
                        menuElementsRefs.textGamesE.current.setAttribute("visibility", "visible");
                    }, 120);
                    setTimeout(function() {
                        menuElementsRefs.textGamesS.current.setAttribute("visibility", "visible");
                        menuElementsRefs.linkGames.current.setAttribute("visibility", "visible");
                    }, 150);
                };

                let pathGamesOffset = 162;
                let intervalGames = setInterval(offsetGames, animDeltaTime);
                function offsetGames() {
                    if (pathGamesOffset > 0) {
                        pathGamesOffset = Math.max(pathGamesOffset - animSpeed, 0);
                        menuElementsRefs.pathGames.current.setAttribute("stroke-dashoffset", pathGamesOffset);
                    } else {
                        clearInterval(intervalGames);
                        setGamesVisible();

                        let circleGamesOffset = 13;
                        let intervalGamesCircle = setInterval(offsetGamesCircle, animDeltaTime);
                        function offsetGamesCircle() {
                            if (circleGamesOffset > 0) {
                                circleGamesOffset = Math.max(circleGamesOffset - animSpeed, 0);
                                menuElementsRefs.circleGames0.current.setAttribute("stroke-dashoffset", circleGamesOffset);
                                menuElementsRefs.circleGames1.current.setAttribute("stroke-dashoffset", circleGamesOffset);
                            } else {
                                clearInterval(intervalGamesCircle);
                            };
                        };
                    };
                };

                function setDesignVisible() {
                    setTimeout(function() {
                        menuElementsRefs.textDesignD.current.setAttribute("visibility", "visible");
                    }, 30);
                    setTimeout(function() {
                        menuElementsRefs.textDesignE.current.setAttribute("visibility", "visible");
                    }, 60);
                    setTimeout(function() {
                        menuElementsRefs.textDesignS.current.setAttribute("visibility", "visible");
                    }, 90);
                    setTimeout(function() {
                        menuElementsRefs.textDesignI.current.setAttribute("visibility", "visible");
                    }, 120);
                    setTimeout(function() {
                        menuElementsRefs.textDesignG.current.setAttribute("visibility", "visible");
                    }, 150);
                    setTimeout(function() {
                        menuElementsRefs.textDesignN.current.setAttribute("visibility", "visible");
                        menuElementsRefs.linkDesign.current.setAttribute("visibility", "visible");
                    }, 180);
                };

                let pathDesignOffset = 210;
                let intervalDesign = setInterval(offsetDesign, animDeltaTime);
                function offsetDesign() {
                    if (pathDesignOffset > 0) {
                        pathDesignOffset = Math.max(pathDesignOffset - animSpeed, 0);
                        menuElementsRefs.pathDesign.current.setAttribute("stroke-dashoffset", pathDesignOffset);
                    } else {
                        clearInterval(intervalDesign);
                        setDesignVisible();

                        let circleDesignOffset = 13;
                        let intervalDesignCircle = setInterval(offsetDesignCircle, animDeltaTime);
                        function offsetDesignCircle() {
                            if (circleDesignOffset > 0) {
                                circleDesignOffset = Math.max(circleDesignOffset - animSpeed, 0);
                                menuElementsRefs.circleDesign0.current.setAttribute("stroke-dashoffset", circleDesignOffset);
                                menuElementsRefs.circleDesign1.current.setAttribute("stroke-dashoffset", circleDesignOffset);
                            } else {
                                clearInterval(intervalDesignCircle);
                            };
                        };
                    };
                };

                function setStoreVisible() {
                    setTimeout(function() {
                        menuElementsRefs.textStoreS.current.setAttribute("visibility", "visible");
                    }, 30);
                    setTimeout(function() {
                        menuElementsRefs.textStoreT.current.setAttribute("visibility", "visible");
                    }, 60);
                    setTimeout(function() {
                        menuElementsRefs.textStoreO.current.setAttribute("visibility", "visible");
                    }, 90);
                    setTimeout(function() {
                        menuElementsRefs.textStoreR.current.setAttribute("visibility", "visible");
                    }, 120);
                    setTimeout(function() {
                        menuElementsRefs.textStoreE.current.setAttribute("visibility", "visible");
                        menuElementsRefs.linkStore.current.setAttribute("visibility", "visible");
                    }, 150);
                };

                let pathStoreOffset = 257;
                let intervalStore = setInterval(offsetStore, animDeltaTime);
                function offsetStore() {
                    if (pathStoreOffset > 0) {
                        pathStoreOffset = Math.max(pathStoreOffset - animSpeed, 0);
                        menuElementsRefs.pathStore.current.setAttribute("stroke-dashoffset", pathStoreOffset);
                    } else {
                        clearInterval(intervalStore);
                        setStoreVisible();

                        let circleStoreOffset = 13;
                        let intervalStoreCircle = setInterval(offsetStoreCircle, animDeltaTime);
                        function offsetStoreCircle() {
                            if (circleStoreOffset > 0) {
                                circleStoreOffset = Math.max(circleStoreOffset - animSpeed, 0);
                                menuElementsRefs.circleStore0.current.setAttribute("stroke-dashoffset", circleStoreOffset);
                                menuElementsRefs.circleStore1.current.setAttribute("stroke-dashoffset", circleStoreOffset);
                            } else {
                                clearInterval(intervalStoreCircle);
                            };
                        };
                    };
                };

                setTimeout(function() {
                    menuExpandedRef.current = true;
                    menuRunningRef.current = false;
                    menuElementsRefs.buttonMenu.current.setAttribute("display", "true");
                }, 900);

            };

            function collapseMenu() {

                let menuBgOpacity = 0.5;
                let intervalMenuBgOpacity = setInterval(offsetCircleHome, animDeltaTime);
                function offsetCircleHome() {
                    if (menuBgOpacity > 0) {
                        menuBgOpacity = Math.max(menuBgOpacity - 0.025, 0);
                        menuElementsRefs.menuBg.current.setAttribute("opacity", menuBgOpacity);
                    } else {
                        clearInterval(intervalMenuBgOpacity);
                    };
                };

                menuElementsRefs.buttonMenu.current.setAttribute("display", "none");

                menuElementsRefs.linkHome.current.setAttribute("visibility", "hidden");
                menuElementsRefs.linkCgi.current.setAttribute("visibility", "hidden");
                menuElementsRefs.linkGames.current.setAttribute("visibility", "hidden");
                menuElementsRefs.linkDesign.current.setAttribute("visibility", "hidden");
                menuElementsRefs.linkStore.current.setAttribute("visibility", "hidden");

                menuElementsRefs.textHomeE.current.setAttribute("visibility", "hidden");
                menuElementsRefs.textCgiI.current.setAttribute("visibility", "hidden");
                menuElementsRefs.textGamesS.current.setAttribute("visibility", "hidden");
                menuElementsRefs.textDesignN.current.setAttribute("visibility", "hidden");
                menuElementsRefs.textStoreE.current.setAttribute("visibility", "hidden");

                setTimeout(function() {
                    menuElementsRefs.textHomeM.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textCgiG.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textGamesE.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDesignG.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textStoreR.current.setAttribute("visibility", "hidden");
                }, 30);

                setTimeout(function() {
                    menuElementsRefs.textHomeO.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textCgiC.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textGamesM.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDesignI.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textStoreO.current.setAttribute("visibility", "hidden");

                    let circleCgiOffset = 0;
                    let intervalCgiCircle = setInterval(offsetCgiCircle, animDeltaTime);
                    function offsetCgiCircle() {
                        if (circleCgiOffset < 13) {
                            circleCgiOffset = Math.min(circleCgiOffset + animSpeed, 13);
                            menuElementsRefs.circleCgi0.current.setAttribute("stroke-dashoffset", circleCgiOffset);
                            menuElementsRefs.circleCgi1.current.setAttribute("stroke-dashoffset", circleCgiOffset);
                        } else {
                            clearInterval(intervalCgiCircle);

                            let pathCgiOffset = 0;
                            let intervalCgi = setInterval(offsetCgi, animDeltaTime);
                            function offsetCgi() {
                                if (pathCgiOffset < 152) {
                                    pathCgiOffset = Math.min(pathCgiOffset + animSpeed, 152);
                                    menuElementsRefs.pathCgi.current.setAttribute("stroke-dashoffset", pathCgiOffset);
                                } else {
                                    clearInterval(intervalCgi);
                                };
                            };
                        };
                    };

                }, 60);

                setTimeout(function() {
                    menuElementsRefs.textHomeH.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textGamesA.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDesignS.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textStoreT.current.setAttribute("visibility", "hidden");

                    let circleHomeOffset = 0;
                    let intervalCircleHome = setInterval(offsetCircleHome, animDeltaTime);
                    function offsetCircleHome() {
                        if (circleHomeOffset < 13) {
                            circleHomeOffset = Math.min(circleHomeOffset + animSpeed, 13);
                            menuElementsRefs.circleHome0.current.setAttribute("stroke-dashoffset", circleHomeOffset);
                            menuElementsRefs.circleHome1.current.setAttribute("stroke-dashoffset", circleHomeOffset);
                        } else {
                            clearInterval(intervalCircleHome);

                            let pathHomeOffset = 0;
                            let intervalHome = setInterval(offsetHome, animDeltaTime);
                            function offsetHome() {
                                if (pathHomeOffset < 169) {
                                    pathHomeOffset = Math.min(pathHomeOffset + animSpeed, 169);
                                    menuElementsRefs.pathHome.current.setAttribute("stroke-dashoffset", pathHomeOffset);
                                } else {
                                    clearInterval(intervalHome);
                                };
                            };
                        };
                    };

                }, 90);

                setTimeout(function() {
                    menuElementsRefs.textGamesG.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textDesignE.current.setAttribute("visibility", "hidden");
                    menuElementsRefs.textStoreS.current.setAttribute("visibility", "hidden");

                    let circleGamesOffset = 0;
                    let intervalGamesCircle = setInterval(offsetGamesCircle, animDeltaTime);
                    function offsetGamesCircle() {
                        if (circleGamesOffset < 13) {
                            circleGamesOffset = Math.min(circleGamesOffset + animSpeed, 13);
                            menuElementsRefs.circleGames0.current.setAttribute("stroke-dashoffset", circleGamesOffset);
                            menuElementsRefs.circleGames1.current.setAttribute("stroke-dashoffset", circleGamesOffset);
                        } else {
                            clearInterval(intervalGamesCircle);

                            let pathGamesOffset = 0;
                            let intervalGames = setInterval(offsetGames, animDeltaTime);
                            function offsetGames() {
                                if (pathGamesOffset < 162) {
                                    pathGamesOffset = Math.min(pathGamesOffset + animSpeed, 162);
                                    menuElementsRefs.pathGames.current.setAttribute("stroke-dashoffset", pathGamesOffset);
                                } else {
                                    clearInterval(intervalGames);
                                };
                            };
                        };
                    };

                    let circleStoreOffset = 0;
                    let intervalStoreCircle = setInterval(offsetStoreCircle, animDeltaTime);
                    function offsetStoreCircle() {
                        if (circleStoreOffset < 13) {
                            circleStoreOffset = Math.min(circleStoreOffset + animSpeed, 13);
                            menuElementsRefs.circleStore0.current.setAttribute("stroke-dashoffset", circleStoreOffset);
                            menuElementsRefs.circleStore1.current.setAttribute("stroke-dashoffset", circleStoreOffset);
                        } else {
                            clearInterval(intervalStoreCircle);

                            let pathStoreOffset = 0;
                            let intervalStore = setInterval(offsetStore, animDeltaTime);
                            function offsetStore() {
                                if (pathStoreOffset < 257) {
                                    pathStoreOffset = Math.min(pathStoreOffset + animSpeed, 257);
                                    menuElementsRefs.pathStore.current.setAttribute("stroke-dashoffset", pathStoreOffset);
                                } else {
                                    clearInterval(intervalStore);
                                };
                            };
                        };
                    };

                }, 120);

                setTimeout(function() {
                    menuElementsRefs.textDesignD.current.setAttribute("visibility", "hidden");

                    let circleDesignOffset = 0;
                    let intervalDesignCircle = setInterval(offsetDesignCircle, animDeltaTime);
                    function offsetDesignCircle() {
                        if (circleDesignOffset < 13) {
                            circleDesignOffset = Math.min(circleDesignOffset + animSpeed, 13);
                            menuElementsRefs.circleDesign0.current.setAttribute("stroke-dashoffset", circleDesignOffset);
                            menuElementsRefs.circleDesign1.current.setAttribute("stroke-dashoffset", circleDesignOffset);
                        } else {
                            clearInterval(intervalDesignCircle);

                            let pathDesignOffset = 0;
                            let intervalDesign = setInterval(offsetDesign, animDeltaTime);
                            function offsetDesign() {
                                if (pathDesignOffset < 210) {
                                    pathDesignOffset = Math.min(pathDesignOffset + animSpeed, 210);
                                    menuElementsRefs.pathDesign.current.setAttribute("stroke-dashoffset", pathDesignOffset);
                                } else {
                                    clearInterval(intervalDesign);
                                };
                            };
                        };
                    };

                }, 150);

                setTimeout(function() {
                    menuExpandedRef.current = false;
                    menuRunningRef.current = false;
                    menuElementsRefs.buttonMenu.current.setAttribute("display", "true");
                }, 900);

            };

            if (type === "click") {

                if (!menuExpandedRef.current) {

                    expandMenu();

                } else if (menuExpandedRef.current) {

                    collapseMenu();

                };

            } else if ((type === "scroll" || type === "scrollend") && !menuExpandedRef.current && scrollPos < 0.05 && scrollPos < scrollPrevPosRef.current) {

                expandMenu();

            } else if (type === "scroll" && menuExpandedRef.current && scrollPos > 0.05) {

                collapseMenu();

            } else if (type === "expand" && !menuExpandedRef.current && scrollPos < 0.05) {

                expandMenu();

            } else if (type === "collapse" && menuExpandedRef.current) {

                collapseMenu();

            } else {

                menuRunningRef.current = false;

            };

            scrollPrevPosRef.current = scrollPos;

        };

    };

    function handleCheckoutPanel(state, reset) {
        if(state === true) {
            setCheckoutPanelState(true);
            handleMenu("collapse");
            pageElementsRefs.bodyElement.current.style.overflowY = "hidden";
        } else {

            if (checkoutPanelContentState === "success" || reset === true) {

                localStorage.removeItem("cartContent");

                setCartContentState([]);
                setCheckoutPanelButtonState(false);
                setCheckoutPanelContentState("cart");
                setCheckoutPanelCountryState("select_country");
                setPaypalSuccessState(false);

            };

            setCheckoutPanelState(false);
            pageElementsRefs.bodyElement.current.style.overflowY = "scroll";

        };
    };

    const router = createBrowserRouter([
        {
            path: "/404",
            element: (<><Error /><Header /><Footer /></>),
        },
        {
            path: "/500",
            element: (<><Error /><Header /><Footer /></>),
        },
        {
            path: "/",
            element: (<><Page /><Header /><Footer /></>),
            children:
            [
                {
                    path: ":page",
                    element: (
                        <Page />
                    ),
                children:
                    [
                        {
                            path: ":section",
                            element: (
                                <Page />
                            ),
                        children:
                            [
                                {
                                    path: ":item",
                                    element: (
                                        <Page />
                                    ),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ]);

    return (
        <AppContext.Provider
            value={{
                handleMenu: handleMenu,
                menuElements: menuElementsRefs,
                menuExpanded: menuExpandedRef.current,
                menuRunning: menuRunningRef.current,
                menuLocked: menuLockedRef.current,
                setMenuLocked: setMenuLocked,
                pageElements: pageElementsRefs,
                cartContentState: cartContentState,
                setCartContentState: setCartContentState,
                checkoutPanelState: checkoutPanelState,
                checkoutPanelContentState: checkoutPanelContentState,
                setCheckoutPanelContentState: setCheckoutPanelContentState,
                checkoutPanelCountryState: checkoutPanelCountryState,
                setCheckoutPanelCountryState: setCheckoutPanelCountryState,
                checkoutPanelButtonState: checkoutPanelButtonState,
                setCheckoutPanelButtonState: setCheckoutPanelButtonState,
                paypalSuccessState: paypalSuccessState,
                setPaypalSuccessState: setPaypalSuccessState,
                handleCheckoutPanel: handleCheckoutPanel,
                scrollToTitle: scrollToTitle,
                scrollToOverview: scrollToOverview,
                scrollToTop: scrollToTop,
                scrollToBottom: scrollToBottom,
                getScrollPosition: getScrollPosition,
                getFadeEndTop: getFadeEndTop,
                getFadeStartBottom: getFadeStartBottom,
                getFadeEndTopPixel: getFadeEndTopPixel,
                getFadeStartBottomPixel: getFadeStartBottomPixel
            }}
        >
            <RouterProvider router={router} />
        </AppContext.Provider>
    );
};

export default App;